import React from "react";
import { useSelector } from "react-redux";

import { RootStateType } from "@store/modules";
import { AuthStateType } from "@store/modules/auth";

const withData = (ComposedComponent: any) => (props: any) => {
  const { isLoggedIn, userAuthState } = useSelector<RootStateType, AuthStateType>((state: RootStateType) => state.auth);

  return <ComposedComponent isLoggedIn={isLoggedIn} cartCount={userAuthState.data?.cartCount} {...props} />;
};

export default withData;
