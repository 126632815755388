import * as React from "react";
import { FC } from "react";
import { WidgetType } from "@amondz/types";
import WidgetSelector from "@views/home/homeList/WidgetSelector";
import { WidgetCoverStyle, WidgetListStyle } from "./style";

export interface IHomeListProps {
  widgetList: WidgetType[];
  onLikeProduct: (widget: WidgetType) => void;
  isFirstWidgetExcluded?: boolean;
}

const WidgetList: FC<IHomeListProps> = ({ widgetList, onLikeProduct, isFirstWidgetExcluded }) => {
  return (
    <WidgetListStyle>
      {widgetList.map((widget: WidgetType, index) => {
        // 홈 최상단 기획전 위젯부터 index 가 시작되기때문에 +1을 해줘야함
        const widgetIndex = isFirstWidgetExcluded ? index + 1 : index;
        return (
          <WidgetCoverStyle key={`${widget.id}`}>
            <WidgetSelector index={widgetIndex} widget={widget} onLikeProduct={onLikeProduct} />
          </WidgetCoverStyle>
        );
      })}
    </WidgetListStyle>
  );
};

export default WidgetList;
