// amondz 슬로건 텍스트
export const AMONDZ_SLOGAN_TEXT = "주얼리, a부터 z까지. 아몬즈";

// 페이지 기본 설명
export const PAGE_BASE_DESCRIPTION = `${AMONDZ_SLOGAN_TEXT} | 전상품 무료배송 | 신규 가입 시 20% 할인`;

// 페이지 키워드
export const PAGE_BASE_KEYWORDS =
  "아몬즈, amondz, 비주얼, bejewel, 주얼리, jewelry, 귀걸이, 목걸이, 반지, 팔찌, 귀찌, " +
  "피어싱, 이어커프, 액세서리, 악세사리, 핸드메이드, 커플링, 이어클라이머, 진주, 초커, 실반지, 각인, 발찌, 하트, 링, " +
  "마스크스트랩, 진주목걸이, 체인, 판도라 반지, 구찌 주얼리, 구찌 반지, 14K, 14k골드, 14k반지, 14k귀걸이, 써지컬, 체인 목걸이," +
  " 코찌,  18k, 체인목걸이, 남자팔찌, 뱅글, 드롭귀걸이, 레이어드, 커플팔찌, 애끼반지, 코인목걸이";

export const PAGE_BASE_NAME = "아몬즈(amondz)";

// 존재하지 않은 상품 리스트 텍스트
export const BASE_EMPTY_PRODUCT_LIST_TEXT = "일치하는 상품이 없습니다.";
