import { WIDGET_LAYOUT_TYPE } from "@amondz/constants";
import {
  BRAND_LIST_PAGE_URL_PATH,
  BRAND_PAGE_URL_PATH,
  EVENT_PAGE_URL_PATH,
  HOME_SUB_PAGE_URL_PATH,
  MY_PAGE_COUPON_URL_PATH,
  MY_PAGE_LIKE_BRAND_URL_PATH,
  MY_PAGE_LIKE_PRODUCT_URL_PATH,
  MY_PAGE_MANAGE_URL_PATH,
  MY_PAGE_ORDER_INFO_URL_PATH,
  MY_PAGE_POINT_URL_PATH,
  MY_PAGE_REVIEW_URL_PATH,
  PAYMENT_CART_URL_PATH,
  PRODUCT_DETAIL_PAGE_URL_PATH,
  PRODUCT_LIST_PAGE_URL_PATH,
  REVIEW_DETAIL_PAGE_URL_PATH,
  WIDGET_PAGE_URL_PATH,
} from "../../url/internalUrlConstants";
import { REVIEW_LIST_TYPE } from "../mypage/mypage";

// 위젯 레이아웃에 따른 섹션 수
export const PC_PRODUCT_WIDGET_LAYOUT_SECTION = {
  [WIDGET_LAYOUT_TYPE.PRODUCT.GRID._2x2]: 4,
  [WIDGET_LAYOUT_TYPE.PRODUCT.GRID._3x2]: 6,
  [WIDGET_LAYOUT_TYPE.PRODUCT.SWIPE.BIG]: 3,
  [WIDGET_LAYOUT_TYPE.PRODUCT.SWIPE.SMALL]: 5,
};
export const MOBILE_PRODUCT_WIDGET_GRID_LAYOUT_SECTION = {
  [WIDGET_LAYOUT_TYPE.PRODUCT.GRID._2x2]: { row: 2, col: 2 },
  [WIDGET_LAYOUT_TYPE.PRODUCT.GRID._3x2]: { row: 3, col: 2 },
};

// 브랜드 위젯 그리드 레이아웃에 따른 섹션 수
export const PC_BRAND_WIDGET_GRID_LAYOUT_SECTION = {
  [WIDGET_LAYOUT_TYPE.BRAND.GRID._1x4]: 4,
  [WIDGET_LAYOUT_TYPE.BRAND.GRID._2x2]: 3,
};

// EVENT_AND_BANNER_DEVICE_TYPE
export const PC_EVENT_AND_BANNER_KEY = "pcBannerImg";
export const MOBILE_EVENT_AND_BANNER_KEY = "mobileBannerImg";

// BANNER_SWIPE_MINIMUM_NUMBER
export const BANNER_SWIPE_MINIMUM_NUMBER = {
  big: 2,
  small: 3,
};

// 메인 기획전 배너 너비
export const MAIN_BANNER_WIDTH = 1024;
// 메인 기획전 배너 autoplaySpeed
export const MAIN_BANNER_AUTOPLAY_SPEED = 5000;

// 배너 linkType
export const BANNER_LINK_TYPE = {
  WIDGET: 0,
  BRAND: 1,
  EVENT: 2,
  PRODUCT_LIST: 3,
  BRAND_LIST: 4,
  LIKE_PRODUCT: 5,
  LIKE_BRAND: 6,
  CART: 7,
  POINT: 8,
  COUPON: 9,
  // REFERRAL: 10,
  ORDER_LIST: 11,
  PRODUCT_REVIEW_WRITE: 12,
  PRODUCT_REVIEW_LIST: 13,
  MEMBER_MANAGE: 14,
  // NOTIFICATION: 15,
  PRODUCT_DETAIL: 16,
  REVIEW_DETAIL: 17,
  // TAG: 18,
  // TAG_DETAIL_GALLERY: 19,
  // TAG_DETAIL_FEED: 20,
  EXTERNAL_LINK: 1000,
  CATEGORY_LINK: 1001,
  HOME: 2000,
};
// 배너 linkType에 따른 url
export const BANNER_LINK_URL = {
  [BANNER_LINK_TYPE.WIDGET]: WIDGET_PAGE_URL_PATH,
  [BANNER_LINK_TYPE.BRAND]: BRAND_PAGE_URL_PATH,
  [BANNER_LINK_TYPE.EVENT]: EVENT_PAGE_URL_PATH,
  [BANNER_LINK_TYPE.PRODUCT_LIST]: PRODUCT_LIST_PAGE_URL_PATH,
  [BANNER_LINK_TYPE.BRAND_LIST]: BRAND_LIST_PAGE_URL_PATH,
  [BANNER_LINK_TYPE.LIKE_PRODUCT]: MY_PAGE_LIKE_PRODUCT_URL_PATH,
  [BANNER_LINK_TYPE.LIKE_BRAND]: MY_PAGE_LIKE_BRAND_URL_PATH,
  [BANNER_LINK_TYPE.CART]: PAYMENT_CART_URL_PATH,
  [BANNER_LINK_TYPE.POINT]: MY_PAGE_POINT_URL_PATH,
  [BANNER_LINK_TYPE.COUPON]: MY_PAGE_COUPON_URL_PATH,
  [BANNER_LINK_TYPE.ORDER_LIST]: MY_PAGE_ORDER_INFO_URL_PATH,
  [BANNER_LINK_TYPE.PRODUCT_REVIEW_WRITE]: `${MY_PAGE_REVIEW_URL_PATH}?type=${REVIEW_LIST_TYPE.WRITE}`,
  [BANNER_LINK_TYPE.PRODUCT_REVIEW_LIST]: `${MY_PAGE_REVIEW_URL_PATH}?type=${REVIEW_LIST_TYPE.HISTORY}`,
  [BANNER_LINK_TYPE.MEMBER_MANAGE]: MY_PAGE_MANAGE_URL_PATH,
  [BANNER_LINK_TYPE.PRODUCT_DETAIL]: PRODUCT_DETAIL_PAGE_URL_PATH,
  [BANNER_LINK_TYPE.REVIEW_DETAIL]: REVIEW_DETAIL_PAGE_URL_PATH,
  [BANNER_LINK_TYPE.EXTERNAL_LINK]: "EXTERNAL LINK",
  [BANNER_LINK_TYPE.CATEGORY_LINK]: PRODUCT_LIST_PAGE_URL_PATH,
  [BANNER_LINK_TYPE.HOME]: HOME_SUB_PAGE_URL_PATH,
};

// 상세 콘텐츠 타입
export const DETAIL_CONTENTS = {
  IMAGE: 0,
  VIDEO: 1,
} as const;
export type DETAIL_CONTENTS_TYPE = (typeof DETAIL_CONTENTS)[keyof typeof DETAIL_CONTENTS];

// 홈 상세, 위젯 상세 필터 여부 타입
export const HOME_EVENT_DETAIL_FILTER = {
  // 미사용
  UNUSED: 0,
  // 사용
  USED: 1,
} as const;
export type HOME_EVENT_DETAIL_FILTER_TYPE = (typeof HOME_EVENT_DETAIL_FILTER)[keyof typeof HOME_EVENT_DETAIL_FILTER];

// 홈 메뉴 위젯 ID
export const HOME_MENU_WIDGET_ID = 0;
