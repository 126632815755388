import React from "react";

import ATypeLayout from "../../base/layout/ATypeLayout";

const withLayout = (ComposedComponent: any) => (props: any) => {
  const { title, ...rest } = props;
  return (
    <ATypeLayout {...props}>
      <ComposedComponent {...rest} />
    </ATypeLayout>
  );
};

export default withLayout;
