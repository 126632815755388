import { useEffect, useRef, useState } from "react";

import { calcRestTime } from "./libs";
import { INITIAL_TIMER_VALUE } from "./constants";

export interface useTimerProps {
  endDate: Date;
  onTimeOver: (isTimeOver: true) => void;
}
export const useTimer = (props: useTimerProps) => {
  const { endDate, onTimeOver } = props;
  const [restTime, setRestTime] = useState(INITIAL_TIMER_VALUE);
  const intervalRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setRestTime(calcRestTime(endDate, { onTimeOver, intervalId: intervalRef.current }));
    }, 1000);
    setRestTime(calcRestTime(endDate, { onTimeOver, intervalId: intervalRef.current }));

    return () => clearInterval(intervalRef.current);
  }, [endDate]);

  useEffect(() => {
    // 탭 활성화 여부를 판단하여 타이머 작동
    const onVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        intervalRef.current = setInterval(() => {
          setRestTime(calcRestTime(endDate, { onTimeOver, intervalId: intervalRef.current }));
        }, 1000);
        setRestTime(calcRestTime(endDate, { onTimeOver, intervalId: intervalRef.current }));
      } else if (document.visibilityState === "hidden") {
        clearInterval(intervalRef.current);
      }
    };

    window.addEventListener("visibilitychange", onVisibilityChange);
    return () => {
      window.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [endDate]);

  const padNumber = (num: number) => (num < 10 ? `0${num}` : num);
  return {
    hours: padNumber(restTime.hours),
    minutes: padNumber(restTime.minutes),
    seconds: padNumber(restTime.seconds),
  };
};
