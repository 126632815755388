import * as React from "react";
import { PropsWithChildren } from "react";
import { useRouter } from "next/router";

import Header from "@components/base/header/Header";
import Footer from "@components/base/footer/Footer";
import Hellobar from "@views/common/modal/HelloBar";
import { GIFT_DELIVERY_URL_PATH } from "@constants/url/internalUrlConstants";
import { useSSRStatus } from "@lib/hooks/useSSRStatus";
import { ATypeLayoutStyle } from "./style";

interface IATypeLayoutProps {
  isLoggedIn: boolean;
  cartCount: number;
}

const ATypeLayout = (props: PropsWithChildren<IATypeLayoutProps>): React.JSX.Element | null => {
  const { children, isLoggedIn, cartCount } = props;
  const router = useRouter();
  const { isSSR } = useSSRStatus();
  const isTopSideFooter = router.pathname.includes(GIFT_DELIVERY_URL_PATH);

  if (isSSR) {
    return null;
  }

  return (
    <ATypeLayoutStyle>
      <Hellobar />
      <Header isLoggedIn={isLoggedIn} cartCount={cartCount} />

      <main>{children}</main>
      <Footer isTopSideFooter={!isTopSideFooter} />
    </ATypeLayoutStyle>
  );
};

export default ATypeLayout;
