import React from "react";
import { WidgetType } from "@amondz/types";
import WidgetMoreButton from "@views/home/homeList/widget/common/WidgetMoreButton";
import { WidgetFooterStyle } from "./style";

interface WidgetFooterProps {
  widgetIndex: number;
  widget: WidgetType;
}
const WidgetFooter = (props: WidgetFooterProps): JSX.Element => {
  const { widget, widgetIndex } = props;

  if (!("buttonText" in widget) || !widget.buttonText) return <></>;

  return (
    <WidgetFooterStyle>
      <WidgetMoreButton
        index={widgetIndex}
        id={widget.id}
        mainTitle={widget.mainTitle || ""}
        widgetType={widget.widgetType}
        eventId={widget.eventId}
        buttonText={widget.buttonText}
      />
    </WidgetFooterStyle>
  );
};

export default WidgetFooter;
