import styled from "@styles/themedComponents";

interface MainWidgetStyleProps {
  page: number;
}

export const PRODUCT_INFO_CONTENT_HEIGHT = 83;
export const MainWidgetStyle = styled.div<MainWidgetStyleProps>`
  line-height: 0;

  .swiper-button-prev,
  .swiper-button-next {
    top: calc((100% - ${PRODUCT_INFO_CONTENT_HEIGHT}px) / 2);
  }
`;

export const TopImageStyle = styled.div`
  display: inline-block;
  width: 50%;
  min-height: 310px;
  padding-right: 8px;

  .product-top-img {
    border-radius: 2px;
  }

  & + div {
    display: inline-block;
    width: 50%;
    padding-left: 8px;
    vertical-align: top;
  }
`;
