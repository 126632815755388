import { GTMViewItemListEventType } from "@amondz/gtm/src/utils";
import { useEffect, useRef } from "react";
import { isEqual } from "lodash";
import GTM from "@amondz/gtm";

export const useTrackChangedListForLogging = (data: GTMViewItemListEventType | null): void => {
  const prevDataRef = useRef<GTMViewItemListEventType | null>(null);

  useEffect(() => {
    if (!data?.items.length) return;

    if (data && data.items.length && !isEqual(data, prevDataRef.current)) {
      GTM.viewItemList(data);
      prevDataRef.current = data;
    }
  }, [data]);
};
