import * as React from "react";
import { FC } from "react";

import Img from "@components/common/Img";
import { BaseCardStyle } from "./style";
import { IMAGE_SIZE } from "@constants/service/common/imageSizeConstants";

interface IBaseCardProps {
  imgUrl: string;
  imgSize?: {
    width: number;
    height: number;
  };
  title?: string | null;
  contents?: string | null;
}

const BaseCard: FC<IBaseCardProps> = ({
  imgUrl,
  imgSize = { width: IMAGE_SIZE.LARGEST.WIDTH, height: IMAGE_SIZE.LARGEST.HEIGHT },
  title,
  contents,
}) => (
  <BaseCardStyle isContents={!!contents}>
    <figure>
      <Img src={imgUrl} width={imgSize.width} height={imgSize.height} />
    </figure>
    {title ? (
      <>
        <strong>{title}</strong>
        <p>{contents}</p>
      </>
    ) : null}
  </BaseCardStyle>
);

export default BaseCard;
