import * as React from "react";
import { FC } from "react";
import Link from "next/link";
import { BannerStateType } from "@amondz/types";
import BaseCard from "@components/common/BaseCard";
import { getUrlToLinkType } from "@lib/utility/linkType";
import { MOBILE_EVENT_AND_BANNER_KEY, PC_EVENT_AND_BANNER_KEY } from "@constants/service/home/home";
import { INTRO_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import Slider from "../../../../../../components/common/Slider";
import { ScrollWidgetItemStyle, ScrollWidgetStyle } from "./style";

interface IScrollWidgetProps {
  itemList: BannerStateType[];
  imageTarget: typeof PC_EVENT_AND_BANNER_KEY | typeof MOBILE_EVENT_AND_BANNER_KEY;
  onClickWidgetItem: (param: { index: number; item: BannerStateType }) => void;
}

const ScrollWidget: FC<IScrollWidgetProps> = (props) => {
  const { itemList, imageTarget, onClickWidgetItem } = props;

  const drawList = (): React.JSX.Element[] => {
    return itemList.map((item, index) => (
      <Link key={index} href={getUrlToLinkType(item.linkType, item.linkUrl, item.id) || INTRO_PAGE_URL_PATH} passHref>
        <ScrollWidgetItemStyle onClick={() => onClickWidgetItem({ index, item })}>
          <BaseCard
            imgUrl={item[imageTarget]?.imgUrl}
            imgSize={{ width: item[imageTarget]?.width, height: item[imageTarget]?.height }}
            title={item.title}
            contents={item.contents}
          />
        </ScrollWidgetItemStyle>
      </Link>
    ));
  };

  return (
    <ScrollWidgetStyle>
      <Slider loop={itemList.length > 2} slidesPerView={2}>
        {drawList()}
      </Slider>
    </ScrollWidgetStyle>
  );
};

export default ScrollWidget;
