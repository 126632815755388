import styled from "@styles/themedComponents";

export const WidgetHeaderStyle = styled.div`
  margin-bottom: 24px;
  text-align: center;

  strong {
    font-size: 26px;
    font-weight: bold;
    line-height: 38px;
    letter-spacing: -0.6px;
  }

  p {
    margin-top: 4px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: ${(props) => props.theme.color.gray_01};
  }
`;
