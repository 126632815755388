import * as React from "react";
import { memo } from "react";
import { useTimer } from "@amondz/hooks";

import { DotStyle, TimerStyle, TimeSegmentStyle } from "./style";

export interface TimerProps {
  endDate: Date;
  onTimeOver: (isTimeOver: true) => void;
}
const Timer = (props: TimerProps) => {
  const { endDate, onTimeOver } = props;
  const { hours, minutes, seconds } = useTimer({ endDate, onTimeOver });

  return (
    <TimerStyle>
      <TimeSegmentStyle>
        <span>{hours}</span>
      </TimeSegmentStyle>
      <DotStyle />
      <TimeSegmentStyle>
        <span>{minutes}</span>
      </TimeSegmentStyle>
      <DotStyle />
      <TimeSegmentStyle>
        <span>{seconds}</span>
      </TimeSegmentStyle>
    </TimerStyle>
  );
};

export default memo(Timer);
