import { useState, useEffect, RefObject } from "react";

interface UseStickyOptions {
  topOffset?: number;
}

export const useSticky = (ref: RefObject<HTMLElement>, { topOffset = 0 }: UseStickyOptions = {}): boolean => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!ref.current) {
        return;
      }
      const sticky = ref.current.offsetTop - topOffset;
      setIsSticky(window.pageYOffset > sticky);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [ref, topOffset]);
  return isSticky;
};
