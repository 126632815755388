import styled from "@styles/themedComponents";

export const TimerStyle = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
`;

export const TimeSegmentStyle = styled.div`
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.adsColor.white};
  background: ${(props) => props.theme.adsColor.black};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.32);
  color: ${(props) => props.theme.adsColor.white};
  text-align: center;
  font-family: ${(props) => props.theme.fonts.barlow};
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 4px;
  padding-bottom: 4px;
  width: 84px;

  span {
    margin-right: -4px;
  }
`;

export const DotStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  &:after,
  &:before {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 1px;
    background: ${(props) => props.theme.adsColor.black};
  }
`;
