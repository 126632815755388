import styled from "@styles/themedComponents";

export const GalleryWidgetStyle = styled.div`
  margin-left: -5px;
  margin-bottom: -5px;

  .widget-product {
    display: inline-block;
    width: calc(25% - 5px);
    line-height: 0;
    margin-left: 5px;
    margin-bottom: 5px;
  }
`;
