import { INITIAL_TIMER_VALUE } from "./constants";

interface calcRestTimeProps {
  onTimeOver?: (isTimeOver: true) => void;
  intervalId?: NodeJS.Timeout;
}
export const calcRestTime = (_endDate: Date, props?: calcRestTimeProps) => {
  const now = new Date();
  const difference = _endDate.getTime() - now.getTime();

  if (difference <= 0) {
    // 타이머 종료 시간이 지났을 경우
    clearInterval(props?.intervalId);

    if (props?.onTimeOver) {
      props?.onTimeOver(true);
    }

    return INITIAL_TIMER_VALUE;
  }

  const hours = Math.floor(difference / (1000 * 60 * 60));
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.round((difference % (1000 * 60)) / 1000);

  return { hours, minutes, seconds };
};
