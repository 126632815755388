import React from "react";
import { numberWithCommas } from "@amondz/utils";
import { PriceInfoStyle } from "@views/home/homeList/widget/TimeDeal/ProductCard/style";

export interface PriceInfoProps {
  type?: "default" | "soldOut";
  salesPrice: number;
  originPrice?: number;
  discountRate?: number;
  subText?: string;
}
export const PriceInfo = (props: PriceInfoProps) => {
  const { type = "default", salesPrice, originPrice, discountRate, subText } = props;

  const Price = ({ className, value }: { className: string; value: number }) => {
    return (
      <span className={className}>
        <span className="price">{numberWithCommas(value)}</span>
        <span className="unit">원</span>
      </span>
    );
  };

  if (type === "soldOut") {
    return (
      <PriceInfoStyle className="sold-out">
        <Price className="sales-price" value={salesPrice} />
        {subText ? <span className="sub-text">({subText})</span> : null}
      </PriceInfoStyle>
    );
  }
  return (
    <PriceInfoStyle>
      {discountRate ? <span className="discount-rate">{discountRate}%</span> : null}
      <Price className="sales-price" value={salesPrice}></Price>
      {originPrice ? <Price className="origin-price" value={originPrice} /> : null}
      {subText ? <span className="sub-text">({subText})</span> : null}
    </PriceInfoStyle>
  );
};
