import { useCallback } from "react";

import cookies from "@lib/utility/cookies";
import { AMONDZ_WIDGET_ID_KEY } from "@constants/service/auth/auth";

/**
 * 위젯 아이템 클릭 시 widgetId 를 cookie 에 저장하는 hook
 */
const useClickWidgetItem = () => {
  return useCallback((widgetId: number | string) => {
    cookies.set(AMONDZ_WIDGET_ID_KEY, widgetId, { path: "/" });
  }, []);
};

export default useClickWidgetItem;
