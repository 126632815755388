import styled, { createGlobalStyle, DeviceSize } from "@styles/themedComponents";
import { MAIN_HEADER_HEIGHT } from "@constants/service/base/header";

export const GlobalStyle = createGlobalStyle`
  main {
    background-color: ${(props) => props.theme.color.amondz_white};
  }
`;

export const BaseInnerLayoutStyle = styled.div`
  position: relative;
  max-width: 1024px;
  min-height: calc(100vh - ${MAIN_HEADER_HEIGHT[DeviceSize.DESKTOP]}px);
  margin: 0 auto 100px;
  padding: 0 30px;
  font-family: ${(props) => props.theme.fonts.notoSansCJKkr};
  color: ${(props) => props.theme.color.amondz_black};
`;
