import styled from "@styles/themedComponents";

interface IIndicatorWidgetStyleProps {
  margin: boolean;
}
export const IndicatorWidgetStyle = styled.div<IIndicatorWidgetStyleProps>`
  .swiper-pagination-bullets {
    bottom: 14%;
  }

  .swiper {
    border-radius: 2px;
    overflow: hidden;
  }
`;
