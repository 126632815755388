import * as React from "react";
import { FC, useEffect, useRef, useState } from "react";
import Link from "next/link";

import { Card } from "@amondz/design-system";
import Img from "@components/common/Img";
import { ImageUrlStateType } from "@store/modules/home";
import { ProductCardStateType } from "@store/modules/common/types";
import { TODAY_DELIVERY_FILTER } from "@constants/service/common/common";
import { IMAGE_SIZE } from "@constants/service/common/imageSizeConstants";
import { PRODUCT_DETAIL_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import { PRODUCT_STATUS_V1 } from "@amondz/constants";
import Slider from "../../../../../../components/common/Slider";
import { MainWidgetStyle, TopImageStyle } from "./style";

interface IMainWidgetProps {
  topImage?: ImageUrlStateType;
  widgetOrEventDetailPageUrl: string;
  section?: number;
  itemList: ProductCardStateType[];
  onClickLike: (pid: number, itemIndex: number) => void;
  onClickWidgetItem: (param: { index: number; item: ProductCardStateType }) => void;
}

const MainWidget: FC<IMainWidgetProps> = (props) => {
  const { itemList, topImage, widgetOrEventDetailPageUrl, section = 1, onClickLike, onClickWidgetItem } = props;
  const [sectionValue, setSectionValue] = useState(section);
  const topImageEl = useRef<HTMLDivElement>(null);

  // 위젯 레이아웃 너비와 섹션에 맞춰 변경
  useEffect(() => {
    if (topImage) {
      setSectionValue(2);
    } else {
      setSectionValue(section);
    }
  }, [section]);

  return (
    <MainWidgetStyle page={Math.ceil(itemList.length / sectionValue)}>
      {topImage && (
        <TopImageStyle className="widget-top-image" ref={topImageEl}>
          <Link href={widgetOrEventDetailPageUrl}>
            <Img className="product-top-img" src={topImage.url} width={topImage.width} height={topImage.height} />
          </Link>
        </TopImageStyle>
      )}
      <Slider slidesPerView={sectionValue} slidesPerGroup={sectionValue}>
        {itemList.map((item, index) => {
          return (
            <Link
              key={index}
              href={`${PRODUCT_DETAIL_PAGE_URL_PATH}/[id]`}
              as={`${PRODUCT_DETAIL_PAGE_URL_PATH}/${item.id}`}
            >
              <Card
                type="basic"
                image={<Img src={item.imgUrl} width={IMAGE_SIZE.LARGE.WIDTH} height={IMAGE_SIZE.LARGE.HEIGHT} />}
                productName={item.name}
                brandName={item.storeName}
                status={item.status === PRODUCT_STATUS_V1.SOLD_OUT ? "soldOut" : "default"}
                salesPrice={item.salesPrice}
                discountRate={item.discountRate}
                likeCount={item.likeCount}
                isLike={!!item.likeFlag}
                isTodayDelivery={item.isTodayDelivery === TODAY_DELIVERY_FILTER.TODAY_DELIVERY}
                onClickCard={() => onClickWidgetItem({ index, item })}
                onClickLike={() => onClickLike(item.id, index)}
              />
            </Link>
          );
        })}
      </Slider>
    </MainWidgetStyle>
  );
};

export default MainWidget;
