import styled from "@styles/themedComponents";

export const MainEventWidgetStyle = styled.div`
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  .swiper-pagination-lock {
    display: block;
  }

  .swiper {
    overflow: hidden;
    border-radius: 8px;
  }
`;
