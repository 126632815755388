import * as React from "react";
import { ReactNode } from "react";
import { ads_color, TodayDeliveryBadge } from "@amondz/design-system";
import { PriceInfo } from "@views/home/homeList/widget/TimeDeal/ProductCard/PriceInfo";
import { AddOnStyle, BrandNameStyle, ProductCardStyle, ProductNameStyle, TodayDeliveryBadgeStyle } from "./style";

export interface ProductCardProps {
  image?: ReactNode;
  brandName: string;
  productName: string;
  status?: "default" | "soldOut";
  salesPrice?: number;
  originPrice?: number;
  discountRate?: number;
  priceSubText?: string;
  isTodayDelivery?: boolean;
  addOn?: any;
}
const ProductCard = (props: ProductCardProps) => {
  const {
    image,
    brandName,
    productName,
    status,
    salesPrice,
    originPrice,
    discountRate,
    priceSubText,
    isTodayDelivery,
    addOn,
  } = props;

  return (
    <ProductCardStyle>
      <div className="card-image">{image}</div>
      <div className="card-info">
        <div>
          <BrandNameStyle>{brandName}</BrandNameStyle>
          <ProductNameStyle>{productName}</ProductNameStyle>
          {!!salesPrice ? (
            <PriceInfo
              type={status}
              salesPrice={salesPrice}
              originPrice={originPrice}
              discountRate={discountRate}
              subText={priceSubText}
            />
          ) : null}
          {isTodayDelivery && (
            <TodayDeliveryBadgeStyle>
              <TodayDeliveryBadge color={status === "soldOut" ? ads_color.gray_07 : ads_color.purple_04} />
            </TodayDeliveryBadgeStyle>
          )}
          <AddOnStyle>{addOn}</AddOnStyle>
        </div>
      </div>
    </ProductCardStyle>
  );
};

export default ProductCard;
