import styled from "@styles/themedComponents";

interface ISwipeWidgetStyleProps {
  size: "big" | "small";
  swipe: boolean;
  edge: boolean;
}
export const SwipeWidgetStyle = styled.div<ISwipeWidgetStyleProps>`
  .swiper {
    .swiper-wrapper {
      margin-left: ${({ size, edge }) =>
        edge ? "0" : size === "big" ? "calc((100% - (90% + 16px)) / 2)" : "calc((100% - (90% + 32px)) / 2)"};
    }
  }
  .swiper-slide {
    width: ${({ size, swipe }) => {
      if (size === "big") {
        return swipe ? "calc(50% - 8px)" : "45%";
      } else if (size === "small") {
        return swipe ? "calc((100% - 32px) / 3)" : "30%";
      }
    }};
  }

  img {
    border-radius: 2px;
  }
`;
