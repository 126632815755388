import * as React from "react";
import { FC } from "react";
import Router, { useRouter } from "next/router";
import { ClickGtmEventLogging, GTM_CONTENT_TYPE, GTM_EVENT } from "@amondz/gtm";
import { PAGE_NAME } from "@amondz/constants-page";
import { WIDGET, WIDGET_NAME } from "@amondz/constants";
import { EVENT_PAGE_URL_PATH, WIDGET_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import { BUTTON_COLOR, BUTTON_SIZE } from "@constants/enum/baseEnums";
import { MoreBtnStyle } from "./style";

interface IWidgetMoreButtonProps {
  index: number;
  id: number;
  mainTitle: string;
  widgetType: (typeof WIDGET)[keyof typeof WIDGET];
  eventId?: number | null;
  buttonText: string;
}

const WidgetMoreButton: FC<IWidgetMoreButtonProps> = (props) => {
  const { index, id, mainTitle, widgetType, eventId, buttonText } = props;
  const router = useRouter();

  const onClickMore = () => {
    if (eventId) {
      return Router.push(`${EVENT_PAGE_URL_PATH}/${eventId}`);
    }
    return Router.push(`${WIDGET_PAGE_URL_PATH}/${id}`);
  };

  return (
    <ClickGtmEventLogging
      event={GTM_EVENT.CLICK_WIDGET_MORE}
      property={{
        groupId: id,
        groupName: mainTitle || "",
        groupIndex: index + 1,
        groupType: WIDGET_NAME[widgetType],
        buttonText: buttonText,
        pageName: PAGE_NAME[router.route],
        contentType: GTM_CONTENT_TYPE.WIDGET_MORE,
      }}
    >
      <MoreBtnStyle color={BUTTON_COLOR.WHITE} size={BUTTON_SIZE.MEDIUM} text={buttonText} onClick={onClickMore} />
    </ClickGtmEventLogging>
  );
};

export default WidgetMoreButton;
