import * as React from "react";
import { FC } from "react";
import Link from "next/link";
import { EventStateType } from "@amondz/types";
import Img from "@components/common/Img";
import { MAIN_BANNER_AUTOPLAY_SPEED, PC_EVENT_AND_BANNER_KEY } from "@constants/service/home/home";
import { EVENT_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import Slider, { formatFractionIndex } from "../../../../../../components/common/Slider";
import { MainEventWidgetStyle } from "./style";

interface IMainEventWidgetProps {
  className: string | undefined;
  itemList: EventStateType[];
  onClickWidgetItem: (param: { index: number; item: EventStateType }) => void;
}

const MainEventWidget: FC<IMainEventWidgetProps> = (props) => {
  const { className, itemList, onClickWidgetItem } = props;

  const imageTarget = PC_EVENT_AND_BANNER_KEY;

  return (
    <MainEventWidgetStyle className={className}>
      <Slider
        autoplay={{
          delay: MAIN_BANNER_AUTOPLAY_SPEED,
          disableOnInteraction: false,
        }}
        loop={itemList.length > 1}
        pagination={{
          type: "fraction",
          formatFractionCurrent: formatFractionIndex,
          formatFractionTotal: formatFractionIndex,
        }}
        spaceBetween={0}
      >
        {itemList.map((item, index) => (
          <Link
            key={index}
            href={`${EVENT_PAGE_URL_PATH}/[eid]`}
            as={`${EVENT_PAGE_URL_PATH}/${item.id}`}
            onClick={() => onClickWidgetItem({ index, item })}
          >
            <Img src={item[imageTarget]?.imgUrl} width={item[imageTarget]?.width} height={item[imageTarget]?.height} />
          </Link>
        ))}
      </Slider>
    </MainEventWidgetStyle>
  );
};

export default MainEventWidget;
