import styled from "@styles/themedComponents";

export const GridWidgetStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  row-gap: 20px;

  img {
    border-radius: 2px;
  }
`;
