// todo: 마이페이지 관련, 선물하기 정리 필요
export const PAGE_URL = {
  HOME: "/",
  SUB_HOME: "/home/[id]",
  SEARCH: "/search",
  SIGN_IN: "/auth/signin",
  SIGN_UP: "/auth/signup",
  FIND_ID: "/auth/find/id",
  FIND_PASSWORD: "/auth/find/password",
  EVENT_DETAIL: "/event/[eid]",
  WIDGET_DETAIL: "/widget/[wid]",
  PRODUCT_LIST: "/product/list",
  PRODUCT_DETAIL: "/product/[id]",
  PRODUCT_REVIEW: "/product/review/[id]",
  BRAND_LIST: "/brand/list",
  BRAND_DETAIL: "/brand/[id]",
  PAYMENT_CART: "/payment/cart",
  PAYMENT_CHECKOUT: "/payment/checkout",
  PAYMENT_COMPLETE: "/payment/complete",
};
