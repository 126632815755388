import * as React from "react";
import { WidgetHeaderStyle } from "./style";

interface WidgetHeaderProps {
  title: string | null;
  subTitle?: string | null;
}

const WidgetHeader = (props: WidgetHeaderProps): JSX.Element => {
  const { title, subTitle } = props;

  if (!title) return <></>;

  return (
    <WidgetHeaderStyle>
      <strong>{title}</strong>
      {subTitle && <p>{subTitle}</p>}
    </WidgetHeaderStyle>
  );
};

export default WidgetHeader;
