import * as React from "react";
import { FC } from "react";
import Link from "next/link";

import Img from "@components/common/Img";
import { ProductCardStateType } from "@store/modules/common/types";
import { PRODUCT_DETAIL_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import { IMAGE_SIZE } from "@constants/service/common/imageSizeConstants";
import { GalleryWidgetStyle } from "./style";

interface IGalleryWidgetProps {
  itemList: ProductCardStateType[];
  onClickWidgetItem: (param: { index: number; item: ProductCardStateType }) => void;
}

const GalleryWidget: FC<IGalleryWidgetProps> = ({ itemList, onClickWidgetItem }) => (
  <GalleryWidgetStyle>
    {itemList.map((item, index) => {
      if (!item.imgUrl) return null;
      if (index >= 8) return null;

      return (
        <div key={index} className="widget-product">
          <Link
            href={`${PRODUCT_DETAIL_PAGE_URL_PATH}/[id]`}
            as={`${PRODUCT_DETAIL_PAGE_URL_PATH}/${item.id}`}
            onClick={() => onClickWidgetItem({ index, item })}
          >
            <Img
              className="widget-img"
              src={item.imgUrl}
              width={IMAGE_SIZE.LARGE.WIDTH}
              height={IMAGE_SIZE.LARGE.HEIGHT}
            />
          </Link>
        </div>
      );
    })}
  </GalleryWidgetStyle>
);

export default GalleryWidget;
