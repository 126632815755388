import styled from "@styles/themedComponents";

export const TimeDealWidgetStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const TimeDealWidgetHeaderStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: center;

  strong {
    font-family: ${(props) => props.theme.fonts.notoSansCJKkr};
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.1px;
    color: ${(props) => props.theme.adsColor.black};
  }

  p {
    font-family: ${(props) => props.theme.fonts.notoSansCJKkr};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: ${(props) => props.theme.adsColor.gray_07};
  }
`;

export const TimeDealWidgetBodyStyle = styled.div``;

export const CenteredCropImageStyle = styled.div`
  width: 474px;
  height: 316px;
  display: block;
  overflow: hidden;
  position: relative;

  img {
    border-radius: 2px;
  }
`;

export const TimeOverDimmedStyle = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${(props) => props.theme.fonts.notoSansCJKkr};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.adsColor.white};
  background-color: #00000066;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const CouponButtonStyle = styled.a`
  font-family: ${(props) => props.theme.fonts.notoSansCJKkr};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.adsColor.black};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  text-align: center;
  margin-top: 20px;
  border-radius: 28px;
  border: 1px solid ${(props) => props.theme.adsColor.black}};
`;
