import * as React from "react";
import { useState } from "react";
import Link from "next/link";

import { TimeDealType } from "@amondz/types";
import { PRODUCT_STATUS_V1 } from "@amondz/constants";

import Slider from "@components/common/Slider";
import Img from "@components/common/Img";
import { PRODUCT_DETAIL_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import { ClickWidgetItemParam } from "@views/home/homeList/WidgetSelector/WidgetSelector";
import Timer from "./Timer";
import ProductCard from "./ProductCard";
import {
  CenteredCropImageStyle,
  TimeDealWidgetBodyStyle,
  TimeDealWidgetHeaderStyle,
  TimeDealWidgetStyle,
  TimeOverDimmedStyle,
} from "./style";

export interface TimeDealProps {
  widgetIndex: number;
  widgetItem: TimeDealType;
  onClickWidgetItem: (widgetItem: ClickWidgetItemParam) => void;
}

const TimeDeal = (props: TimeDealProps): null | React.JSX.Element => {
  const { widgetItem, onClickWidgetItem } = props;
  const [isTimeOver, setIsTimeOver] = useState(false);

  if (widgetItem.itemList.length === 0) return null;

  return (
    <TimeDealWidgetStyle>
      <TimeDealWidgetHeaderStyle>
        <strong>{widgetItem.mainTitle}</strong>
        <Timer endDate={new Date(widgetItem.exposureEndDate)} onTimeOver={setIsTimeOver} />
        {widgetItem.subTitle ? <p>{widgetItem.subTitle}</p> : null}
      </TimeDealWidgetHeaderStyle>
      <TimeDealWidgetBodyStyle>
        <Slider slidesPerView={1} slidesPerGroup={1} loop={widgetItem.itemList.length > 1} spaceBetween={0}>
          {widgetItem.itemList.map((item, index) => (
            // widgetItem 의 id 가 타임딜 위젯 아이템에 대한 id 가 아닌 상품 id 로 사용되고있어,
            // 혹시 모를 중복에 대배하여 index 와 합쳐서 key 값 설정
            // https://bejewelteam.slack.com/archives/C0530528GHZ/p1700447948506439?thread_ts=1700445742.001669&cid=C0530528GHZ
            <Link
              key={`${index}-${item.id}`}
              href={`${PRODUCT_DETAIL_PAGE_URL_PATH}/[id]`}
              as={`${PRODUCT_DETAIL_PAGE_URL_PATH}/${item.id}`}
              onClick={() => onClickWidgetItem({ index, id: item.id, name: item.name })}
            >
              <ProductCard
                image={
                  <CenteredCropImageStyle>
                    <Img
                      src={item.pcTimeDealImg.imgUrl}
                      width={item.pcTimeDealImg.width}
                      height={item.pcTimeDealImg.height}
                      alt={`${item.name} 상품 이미지`}
                      layout="fill"
                      objectFit="cover"
                    />
                    {isTimeOver && <TimeOverDimmedStyle>타임딜이 종료됐어요</TimeOverDimmedStyle>}
                  </CenteredCropImageStyle>
                }
                brandName={item.storeName}
                productName={item.name}
                status={item.status === PRODUCT_STATUS_V1.SOLD_OUT ? "soldOut" : "default"}
                salesPrice={item.salesPrice}
                originPrice={item.originPrice}
                discountRate={item.discountRate}
                priceSubText={widgetItem.isUseCoupon ? "쿠폰적용가" : ""}
                isTodayDelivery={!!item.isTodayDelivery}
              />
            </Link>
          ))}
        </Slider>
      </TimeDealWidgetBodyStyle>
    </TimeDealWidgetStyle>
  );
};

export default TimeDeal;
