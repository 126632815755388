import * as React from "react";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Router from "next/router";
import produce from "immer";
import marketingService, { eventType, FromType, useProductDetailFromPage } from "@amondz/marketing-service";
import { GTM_CONTENT_TYPE, GTM_EVENT, useGtmEventLogging } from "@amondz/gtm";
import { ProductWidgetType, WidgetType } from "@amondz/types";
import { WIDGET, WIDGET_LAYOUT_TYPE, WIDGET_NAME } from "@amondz/constants";
import gtm, { subCategoryName } from "@lib/utility/gtm";
import baseAPI from "@services/apis/baseAPI";
import { RootStateType } from "@store/modules";
import { twoButtonModalToggle } from "@store/modules/base";
import { AuthStateType } from "@store/modules/auth";
import { ProductCardStateType } from "@store/modules/common/types";
import { PC_PRODUCT_WIDGET_LAYOUT_SECTION } from "@constants/service/home/home";
import { REQUIRED_LOG_IN_INFO_MODAL_CONTENTS } from "@constants/service/auth/modal";
import { EVENT_PAGE_URL_PATH, SIGN_IN_PAGE_URL_PATH, WIDGET_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import { STATUS_CODE_COMMON } from "@constants/status/code/statusCodeCommon";
import { LIKE_EVENT_THROTTLE_SPEED, LIKE_STATUS } from "@constants/service/common/common";
import { ClickWidgetItemParam } from "@views/home/homeList/WidgetSelector/WidgetSelector";
import WidgetHeader from "@views/home/homeList/widget/common/WidgetHeader";
import WidgetFooter from "@views/home/homeList/widget/common/WidgetFooter";
import GalleryWidget from "./GalleryWidget";
import MainWidget from "./MainWidget";

export interface IProductProps {
  widgetIndex: number;
  widgetItem: ProductWidgetType;
  onLikeProduct: (widget: WidgetType) => void;
  onClickWidgetItem: (widgetItem: ClickWidgetItemParam) => void;
}

const Product = (props: IProductProps): JSX.Element | null => {
  const { widgetIndex, widgetItem, onLikeProduct, onClickWidgetItem } = props;
  const { isLoggedIn, userAuthState } = useSelector<RootStateType, AuthStateType>((state: RootStateType) => state.auth);
  const [isThrottle, setThrottle] = useState(true);
  const dispatch = useDispatch();
  const { trigger } = useGtmEventLogging();
  const { setFrom } = useProductDetailFromPage();

  const handleClickWidgetItem = ({ index, item }: { index: number; item: ProductCardStateType }): void => {
    onClickWidgetItem({
      index,
      id: item.id,
      name: item.name,
    });

    // 블럭스 추천 상품을 통해 상품 상세 페이지 접근 시 from 정보를 보내줘야함
    if (widgetItem.isProductRecommend) {
      setFrom(FromType.HOME);
    }
  };

  const onClickLike = useCallback(
    async (pId: number, itemIndex: number, widgetId: number) => {
      if (!isLoggedIn) {
        dispatch(
          twoButtonModalToggle({
            visible: true,
            msg: REQUIRED_LOG_IN_INFO_MODAL_CONTENTS.MSG,
            subMsg: REQUIRED_LOG_IN_INFO_MODAL_CONTENTS.SUB_MSG,
            onClickRightBtn: () =>
              Router.push(`${SIGN_IN_PAGE_URL_PATH}?rtnUrl=${encodeURIComponent(window.history.state.as)}`),
          }),
        );
        return;
      }

      if (!isThrottle) return;

      // 좋아요 버튼 클릭시 쓰로틀 기능을 위해 isThrottle state 설정
      setThrottle(false);
      setTimeout(() => {
        setThrottle(true);
      }, LIKE_EVENT_THROTTLE_SPEED);

      // 좋아요 이벤트 처리
      onLikeProduct(
        produce(widgetItem, (draft) => {
          const product = draft.itemList[itemIndex];
          product.likeFlag = product.likeFlag === LIKE_STATUS.LIKE ? LIKE_STATUS.UN_LIKE : LIKE_STATUS.LIKE;
        }),
      );

      try {
        const { status, data } = await baseAPI.likeProduct({ productId: pId, widgetId });

        if (widgetItem.widgetType === WIDGET.PRODUCT) {
          const product = widgetItem.itemList.find((item) => item.id === pId);
          if (product !== undefined) {
            data.code === LIKE_STATUS.LIKE
              ? marketingService(eventType.likeProduct, {
                  userId: userAuthState.data?.userId,
                  productId: product.id,
                  productName: product.name,
                })
              : marketingService(eventType.unLikeProduct, {
                  userId: userAuthState.data?.userId,
                  productId: product.id,
                  productName: product.name,
                });
            if (data.code === LIKE_STATUS.LIKE) {
              // braze - 상품 좋아요 시
              gtm.addToProductWishlist({
                brandId: product.storeId,
                brandName: product.storeName,
                productId: product.id,
                productName: product.name,
                price: product.salesPrice || 0,
                deliveryType: product.isTodayDelivery || 0,
                isGift: !!product.isGift,
                discountRate: product.discountRate || 0,
                categoryId: product.categoryId,
                categoryName: product.categoryName,
                subCategoryName: subCategoryName(product.productCategoryList),
              });
            }

            trigger(GTM_EVENT.CLICK_WIDGET_ITEM_LIKE, {
              itemId: product.id,
              itemName: product.name,
              itemIndex: itemIndex + 1,
              groupId: widgetItem.id,
              groupName: widgetItem.mainTitle || "",
              groupIndex: widgetIndex + 1,
              groupType: WIDGET_NAME[widgetItem.widgetType],
              status: data.code === LIKE_STATUS.LIKE,
              contentType: GTM_CONTENT_TYPE.WIDGET_ITEM_LIKE,
            });

            //contentType별로 따로 데이터를 수집하기 위해 분리
            trigger(GTM_EVENT.CLICK_PRODUCT_LIKE, {
              productId: product.id,
              productName: product.name,
              productIndex: itemIndex + 1,
              status: data.code === LIKE_STATUS.LIKE,
              contentType: GTM_CONTENT_TYPE.PRODUCT_LIKE,
            });
          }
        }

        // API 응답이 성공이 아닐 시, 로컬 데이터 원래대로 복구
        if (status !== STATUS_CODE_COMMON.SUCCESS) {
          onLikeProduct(
            produce(widgetItem, (draft) => {
              const product = draft.itemList[itemIndex];
              product.likeFlag = product.likeFlag === LIKE_STATUS.LIKE ? LIKE_STATUS.UN_LIKE : LIKE_STATUS.LIKE;
            }),
          );
        }
      } catch (e) {
        console.error(e);
      }
    },
    [widgetItem, isLoggedIn, isThrottle, onLikeProduct, userAuthState.data?.userId],
  );

  /**
   * layoutType 별 맵핑
   */
  const mappingLayoutType = useCallback(() => {
    if (widgetItem.widgetType !== WIDGET.PRODUCT) return;

    const layoutTypes = WIDGET_LAYOUT_TYPE.PRODUCT;
    if (widgetItem.layoutType === layoutTypes.GALLERY) {
      // 공통 버전
      return (
        <GalleryWidget key={widgetItem.id} itemList={widgetItem.itemList} onClickWidgetItem={handleClickWidgetItem} />
      );
    }

    const section = PC_PRODUCT_WIDGET_LAYOUT_SECTION[widgetItem.layoutType];

    const widgetOrEventDetailPageUrl =
      "eventId" in widgetItem && widgetItem.eventId
        ? `${EVENT_PAGE_URL_PATH}/${widgetItem.eventId}`
        : `${WIDGET_PAGE_URL_PATH}/${widgetItem.id}`;
    return (
      <MainWidget
        key={widgetItem.id}
        itemList={widgetItem.itemList}
        topImage={widgetItem.topImage}
        widgetOrEventDetailPageUrl={widgetOrEventDetailPageUrl}
        section={section}
        onClickLike={(pid, itemIndex) => onClickLike(pid, itemIndex, widgetItem.id)}
        onClickWidgetItem={handleClickWidgetItem}
      />
    );
  }, [widgetItem, isThrottle]);

  if (widgetItem.itemList.length === 0) return null;

  return (
    <div>
      <WidgetHeader title={widgetItem.mainTitle} subTitle={widgetItem.subTitle} />
      {mappingLayoutType()}
      <WidgetFooter widgetIndex={widgetIndex} widget={widgetItem} />
    </div>
  );
};

export default Product;
