import styled from "@styles/themedComponents";

export const ScrollWidgetStyle = styled.div`
  .swiper-slide {
    width: 66.18%;
    transition: transform 0.5s ease-in-out;

    figure {
      position: relative;
    }

    &-active {
      transform: scale(1);

      & article {
        strong {
          height: 20px;
        }

        p {
          margin-top: 2px;
          height: 19px;
        }
      }
    }

    &:not(&-active) {
      transform: scale(0.95);

      & article {
        strong,
        p {
          visibility: hidden;
        }
      }

      &:after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      figure:after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${(props) => props.theme.color.amondz_black};
        opacity: 0.55;
      }
    }

    &-next,
    &-prev {
      cursor: pointer;
    }
  }

  @media (max-width: 1200px) {
    .swiper-slide {
      &:not(&-active) {
        transform: scale(0.96);
      }
    }
  }
`;
