import styled from "@styles/themedComponents";

interface IBrandCardStyleProps {
  isContents: boolean;
}

export const BaseCardStyle = styled.article<IBrandCardStyleProps>`
  display: flex;
  flex-direction: column;

  figure {
    display: flex;
    margin: 0;
    padding: 0;

    * {
      width: 100%;
    }
    img {
      border-radius: 2px;
    }
  }

  strong {
    margin-top: 8px;
    font-size: 14px;
    font-weight: bold;
    height: 20px;
    line-height: 20px;
    letter-spacing: -0.5px;
  }

  p {
    margin-top: ${(props) => (props.isContents ? 2 : 0)}px;
    font-size: 13px;
    font-weight: normal;
    height: ${(props) => (props.isContents ? "19px" : "auto")};
    line-height: 19px;
    letter-spacing: -0.5px;
    color: ${(props) => props.theme.color.gray_01};
    ${(props) => props.theme.ellipsis};
  }
`;
