import styled from "@styles/themedComponents";

export const ProductCardStyle = styled.div`
  display: flex;
  gap: 16px;

  > * {
    width: 100%;
    display: flex;
    flex-flow: column;
  }

  .card-info {
    & > div {
      position: relative;
      margin: 16px 0 16px 16px;
      display: flex;
      flex-flow: column;
      height: 100%;
      width: 386px;
    }
  }
`;

export const BrandNameStyle = styled.div`
  font-family: ${(props) => props.theme.fonts.notoSansCJKkr};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.adsColor.black};
  ${(props) => props.theme.ellipsis}
`;

export const ProductNameStyle = styled.div`
  font-family: ${(props) => props.theme.fonts.notoSansCJKkr};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.adsColor.black};
  ${(props) => props.theme.ellipsis}
  margin-top: 4px
`;

export const PriceInfoStyle = styled.div`
  display: flex;
  align-items: baseline;
  gap: 2px;
  margin-top: 8px;

  &.sold-out {
    .sales-price {
      color: ${({ theme }) => theme.adsColor.gray_07};

      .price {
        text-decoration-line: line-through;
      }
    }
  }

  .discount-rate {
    font-family: ${(props) => props.theme.fonts.barlow};
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.64px;
    color: ${(props) => props.theme.adsColor.secondary};
  }

  .sales-price {
    color: ${(props) => props.theme.adsColor.black};

    .price {
      font-family: ${(props) => props.theme.fonts.barlow};
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.64px;
    }
    .unit {
      font-family: ${(props) => props.theme.fonts.notoSansCJKkr};
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: -0.2px;
    }
  }

  .origin-price {
    color: ${(props) => props.theme.adsColor.gray_06};

    .price {
      font-family: ${(props) => props.theme.fonts.barlow};
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 27.279px;
      letter-spacing: -0.56px;
      text-decoration-line: line-through;
    }
    .unit {
      font-family: ${(props) => props.theme.fonts.notoSansCJKkr};
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.2px;
    }
  }

  .sub-text {
    color: ${(props) => props.theme.adsColor.gray_06};
    ${(props) => props.theme.ellipsis}
    font-family: ${(props) => props.theme.fonts.notoSansCJKkr};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.2px;
  }
`;

export const TodayDeliveryBadgeStyle = styled.div`
  margin-top: 8px;
`;

export const AddOnStyle = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`;
