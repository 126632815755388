import * as React from "react";
import { useCallback } from "react";
import { BannerStateType } from "@amondz/types";
import { WIDGET, WIDGET_LAYOUT_TYPE } from "@amondz/constants";
import { PC_EVENT_AND_BANNER_KEY } from "@constants/service/home/home";
import WidgetHeader from "@views/home/homeList/widget/common/WidgetHeader";
import WidgetFooter from "@views/home/homeList/widget/common/WidgetFooter";
import { IBannerProps } from "@views/home/homeList/widget/Banner/types";
import SwipeWidget from "./SwipeWidget";
import IndicatorWidget from "./IndicatorWidget";
import GridWidget from "./GridWidget";
import ScrollWidget from "./ScrollWidget";

const Banner = (props: IBannerProps): React.JSX.Element | null => {
  const { widgetIndex, widgetItem, onClickWidgetItem } = props;

  const imageTarget = PC_EVENT_AND_BANNER_KEY;

  const handleClickWidgetItem = ({ index, item }: { index: number; item: BannerStateType }): void => {
    onClickWidgetItem({
      index,
      id: item.id,
      name: item.title,
    });
  };

  /**
   * layoutType 별 맵핑
   */
  const mappingLayoutType = useCallback(() => {
    if (widgetItem.widgetType !== WIDGET.BANNER) return;

    const layoutTypes = WIDGET_LAYOUT_TYPE.BANNER;
    if ([layoutTypes.MARGIN_YES, layoutTypes.MARGIN_NO].includes(widgetItem.layoutType)) {
      return (
        <IndicatorWidget
          key={widgetItem.id}
          itemList={widgetItem.itemList}
          imageTarget={imageTarget}
          layoutType={widgetItem.layoutType}
          onClickWidgetItem={handleClickWidgetItem}
        />
      );
    } else if ([layoutTypes.SWIPE_BIG, layoutTypes.SWIPE_SMALL].includes(widgetItem.layoutType)) {
      return (
        <SwipeWidget
          key={widgetItem.id}
          itemList={widgetItem.itemList}
          imageTarget={imageTarget}
          layoutType={widgetItem.layoutType}
          onClickWidgetItem={handleClickWidgetItem}
        />
      );
    } else if ([layoutTypes.GRID_1X2, layoutTypes.GRID_2X2].includes(widgetItem.layoutType)) {
      return (
        <GridWidget
          key={widgetItem.id}
          itemList={widgetItem.itemList}
          imageTarget={imageTarget}
          layoutType={widgetItem.layoutType}
          onClickWidgetItem={handleClickWidgetItem}
        />
      );
    } else if (widgetItem.layoutType === layoutTypes.SCROLL) {
      return (
        <ScrollWidget
          itemList={widgetItem.itemList}
          imageTarget={imageTarget}
          onClickWidgetItem={handleClickWidgetItem}
        />
      );
    }
  }, [widgetItem]);

  if (widgetItem.itemList.length === 0) return null;

  return (
    <div>
      <WidgetHeader title={widgetItem.mainTitle} subTitle={widgetItem.subTitle} />
      {mappingLayoutType()}
      <WidgetFooter widgetIndex={widgetIndex} widget={widgetItem} />
    </div>
  );
};

export default Banner;
