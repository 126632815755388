import * as React from "react";
import { FC } from "react";
import Link from "next/link";
import { Swiper as SwiperClass } from "swiper/types";
import { BrandBaseWidgetItemType, BrandCommonWidgetItemType } from "@amondz/types";
import { BRAND_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import BaseCard from "@components/common/BaseCard";
import Slider from "../../../../../../components/common/Slider";
import { ScrollWidgetStyle } from "./style";

interface IScrollWidgetProps {
  itemList: BrandCommonWidgetItemType[];
  onClickWidgetItem: <Item extends BrandBaseWidgetItemType>(param: { index: number; item: Item }) => void;
}

const ScrollWidget: FC<IScrollWidgetProps> = (props) => {
  const { itemList, onClickWidgetItem } = props;

  const onClickSlide = (swiper: SwiperClass): void => {
    if (swiper.clickedSlide.className.match("swiper-slide-next")) {
      swiper.slideNext();
    } else if (swiper.clickedSlide.className.match("swiper-slide-prev")) {
      swiper.slidePrev();
    }
  };

  return (
    <ScrollWidgetStyle>
      <Slider centeredSlides loop slidesPerView={"auto"} spaceBetween={0} onClick={onClickSlide}>
        {itemList.map((item, index) => (
          <Link
            key={index}
            href={`${BRAND_PAGE_URL_PATH}/${item.id}`}
            onClick={() => onClickWidgetItem({ index, item })}
          >
            <BaseCard
              imgUrl={item.topImage.url}
              imgSize={{ width: item.topImage.width, height: item.topImage.height }}
              title={item.title}
              contents={item.contents}
            />
          </Link>
        ))}
      </Slider>
    </ScrollWidgetStyle>
  );
};

export default ScrollWidget;
