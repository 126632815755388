import * as React from "react";

import { GlobalStyle, BaseInnerLayoutStyle } from "./style";

export default (ComposedComponent: any) => (props: any) => (
  <>
    <BaseInnerLayoutStyle {...props}>
      <ComposedComponent {...props} />
    </BaseInnerLayoutStyle>
    <GlobalStyle />
  </>
);
